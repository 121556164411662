const ROUTES = {
    // Logged out + logged in
    HOME: '/',
    DASHBOARD: '/dashboard',
    PLANNER_LP: '/priority-planner',
    PLANNER: '/priority-planner/plan',
    CLASSES_LP: '/gre-live-classes',
    CLASSES: '/classes',
    ON_DEMAND: '/on-demand',
    VOCAB: '/vocab-connect',
    VOCAB_PRACTICE: '/vocab-connect/practice',
    VOCAB_REPORT: '/vocab-connect/report',
    PRICING: '/premium',
    LOGIN: '/login',
    BLOG_LISTING: '/blogs',
    BLOGS_AND_GUIDES: '/gre',
    LEAD_MAGNET: '/free-starter-kit',
    FREE_STARTER_KIT: 'free-starter-kit',
    ON_DEMAND_V2: '/on-demand-v2',

    // Logged in view pages
    PAYMENT: '/premium/pay',
    PERFORMANCE: '/performance',
    PROFILE: '/profile',
    QUESTIONS: '/questions',
    FREEBIES: '/freebies',

    // External links
    CONTACT: 'https://tally.so/r/3E5eVq',
    LIVE_DEMO: process.env.LIVE_DEMO_MEET_LINK,
    BOOK_DEMO: 'https://tally.so/r/3E5eVq'
};

export { ROUTES };
