export default (http, resource, apiVersion) => ({
    getArticle(slug) {
        const method = 'get';
        const url = `/${resource}/${apiVersion}/get-article/${slug}`;
        return http({ method, url });
    },
    getBlogListing(page) {
        const method = 'get';
        const url = `/${resource}/${apiVersion}/get-all-articles?page=${page}`;
        return http({ method, url });
    }
});
